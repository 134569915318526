import './MainScene.css';
import { Link } from 'react-router-dom';


var jobList = [], story = ""


function JobCard(props) {
    const path = "/job/" + props.id
    return (
        <div className="jobCard">
            <div className="sortware">
                <div className="light1"></div>
                <div className="light2"></div>
                <div className="sortwareName" dangerouslySetInnerHTML={{__html: props.sortware}}></div>
            </div>
            <div className="experience">
                <span className="upper">{props.experience}</span>
                <br />
                <span className="lower">{props.job}</span>
            </div>
            <div className="openPosition">
                Open Position: {props.openPosition}
            </div>
            <div className="salary">
                <Link
                    to={path}
                    className="salaryClick"
                >Job Details</Link>

            </div>
        </div>
    )
}


export default function MainScene(props) {
    if (props.data !== null) {
        jobList = props.data.jobList
        story = props.data.story
    }
    function MenuOpenClose() {
        console.log("menu");
        document.querySelector(".menuMobile").classList.toggle("active");
    }
    return <>
        <div className="main">
            <div className="container">
                <div className="topBar">
                    <img src="https://storage.tapuniverse.com/tapuniverse-website/TapUniverseLogo.png" alt="TapUniverse Logo" className="tapLogo" />
                    <div className="menu">
                        <a className="ourStoryText" href="#ourStory" style={{ textDecoration: "none" }}>Our Story</a>
                        <Link to="/job/1" style={{ textDecoration: "none" }} className="careersText" onClick={function () { window.scrollTo(0, 0); }}>Careers</Link>
                        <div className="contactUsText" onClick={function () { window.open('mailto:career@tapuniverse.com'); }}>Contact Us</div>
                    </div>
                </div>
                <div className="weMake">
                    <div className="weMakeImg">
                        {/* <img src="https://storage.tapuniverse.com/tapuniverse-website/GradientBackground.png" alt="Gradient Background" className="gradientBackground" /> */}
                        <div className="circle"></div>
                        <div className="decor1"></div>
                        <div className="decor2"></div>
                        <img src="https://storage.tapuniverse.com/tapuniverse-website/Keyvisual.png" alt="Key Visual" className="keyVisual" />
                    </div>
                    <div className="weMakeText">WE BUILD<br />
                        THE NEXT GENERATION<br /> OF APPS
                    </div>
                </div>
                <div id="hrefConnectStory" style={{ top: "600px", position: "absolute" }}></div>
                <div className="ourStory" id="ourStory">
                    <div className="halfwidth">
                        <div className="storyImgCarry block-gray">
                            {/* <img src="https://storage.tapuniverse.com/tapuniverse-website/OurStory.png" alt="Our Story" className="story" /> */}
                            <div className="storyText">
                                <div className="ourTextTittle">Our Story</div>
                                <br />
                                <div className="ourTextContent" dangerouslySetInnerHTML={{__html: story}}></div>
                            </div>
                        </div>
                    </div>
                    <div className="halfwidth">
                        <div className="designImgCarry block-gray">
                            <img src="https://storage.tapuniverse.com/tapuniverse-website/GradientElipse.png" alt="Design" className="design" />
                            <img src="https://storage.tapuniverse.com/tapuniverse-website/DesignIllustration.png" alt="Design2" className="design2" />
                            <div className="designText">
                                we start with<br />a <span style={{ color: "#3292FF" }}>carefully<br />crafted design</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="weBuildTest">
                    <div className="halfwidth">
                        <div className="weBuild block-gray">
                            <img src="https://storage.tapuniverse.com/tapuniverse-website/DevelopmentIllustration.png" alt="Development" className="build" />
                            <div className="buildText">we build it<br />with our<br />
                                <span style={{ color: "#3292FF" }}>high quality<br />standard</span>
                            </div>
                        </div>
                    </div>
                    <div className="halfwidth">
                        <div className="weTest block-gray">
                            <img src="https://storage.tapuniverse.com/tapuniverse-website/TestIllustration.png" alt="Testcase" className="test" />
                            <div className="testText">we test it.<br />rebuild it.<br />
                                <span style={{ color: "#3292FF" }}>until it's perfect</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="careers block-gray" style={{ background: "url(https://storage.tapuniverse.com/tapuniverse-website/bg.jpg)" }}>
                    <div className="darken">
                    </div>
                    <div className="careersTexts">
                        <div className="careersTitle">We need your talent.</div>
                        <div className="careersContent">
                            If you want to build next level apps with latest technologies and excellent user experience. This is the place for you.
                        </div>
                    </div>
                    <div className="jobList">
                        {jobList.map((job, i) =>
                            <JobCard
                                id={job.id}
                                key={job.id}
                                sortware={job.sortware}
                                experience={job.experience}
                                job={job.jobName}
                                openPosition={job.openPosition}
                                salary={job.salary[0]}
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className="footer">
                <div className="container">
                    <div className="footerAllItems">
                        <div className="footerTittle">
                            <span style={{fontWeight: 600}}>TapUniverse</span><br />
                            <div style={{ marginRight: "15px", fontSize: "14px" }}>Copyright ©2021 TapUniverse LLC. All rights reserverd.</div>
                        </div>
                        <div className="footerContent">
                            <div className="divider">|</div>
                            {/* <Link to="/terms" style={{ marginRight: "15px", marginLeft: "15px", cursor: "pointer", textDecoration: "none" }} className="terms" >Terms of Service</Link> */}
                            <a href="/terms" style={{ marginRight: "15px", marginLeft: "15px", cursor: "pointer", textDecoration: "none" }} className="terms" >Terms of Service</a>
                            <div className="divider">|</div>
                            {/* <Link to="/policy" style={{ marginRight: "15px", marginLeft: "15px", cursor: "pointer", textDecoration: "none" }} className="policy" >Privacy Policy</Link> */}
                            <a href="/policy" style={{ marginRight: "15px", marginLeft: "15px", cursor: "pointer", textDecoration: "none" }} className="policy" >Privacy Policy</a>
                            <div className="divider">|</div>
                            <div style={{ marginLeft: "15px", cursor: "pointer" }} className="contact" onClick={function () { window.open('mailto:career@tapuniverse.com'); }}>Contact Us</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="menuMobile">
                <div className="menuButton" onClick={MenuOpenClose}>
                    <div className="menuIcon">
                        <div className="line line1"></div>
                        <div className="line line2"></div>
                    </div>
                </div>
                <div className="menuBackground"></div>
                <div className="menuList">
                    <a className="ourStoryText" href="#hrefConnectStory" style={{ textDecoration: "none" }} onClick={MenuOpenClose}>Our Story</a>
                    <Link to="/job" style={{ textDecoration: "none" }} className="careersText" onClick={function () { window.scrollTo(0, 0); }}>Careers</Link>
                    <a className="contactUsText" href='mailto:career@tapuniverse.com'>Contact Us</a>
                </div>
            </div>
        </div>
    </>
}
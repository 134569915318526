import './Careers.css'
import { Link, Switch, Route } from 'react-router-dom'
import React, { useState } from 'react'
import Vector from './../../Vector.png'


var jobList = [], contactInfo = []

function JobDetail(props) {
    var job = props.job
    return (
        <div className="ctJobDetail">
            <div className="ctJDTittle">
                <div className="ctJDName">
                    <div className="rowStick"></div>
                    <div className="jobName">{job.experience} {job.jobName}</div>
                    <span className="openpos">{job.openPosition} open position{ job.openPosition > 1 ? "s" : ""}</span>
                </div>
                <div className="ctJDSalary">{job.salary[1]}</div>
                <div className="requires requiresLab requiresTablet" >
                    {job.requires.map((require, i) => <RequireHastag hashtag={require} key={i} />)}
                </div>
            </div>
            <ul className="ctJDContent">
                <div className="ctJDName" style={{ marginTop: "24px", fontSize: "16px" }}><div className="rowStick"></div>Who are we looking for?</div>
                {job.who.map((who, i) => <li className="ctJDJTTitle" key={i}>{who}</li>)}
            </ul>
            <ul className="ctJDContent">
                <div className="ctJDName" style={{ marginTop: "24px", fontSize: "16px" }}><div className="rowStick"></div>Your responsibility:</div>
                {job.workingOn.map((workingOn, i) => <li className="ctJDJTTitle" key={i}>{workingOn}</li>)}
            </ul>
            <ul className="ctJDContent">
                <div className="ctJDName" style={{ marginTop: "24px", fontSize: "16px" }}><div className="rowStick"></div>Job Requirements:</div>
                {job.talentRequires.map((talentRequire, i) => <li className="ctJDJTTitle" key={i}>{talentRequire}</li>)}
            </ul>
            <ul className="ctJDContent">
                <div className="ctJDName" style={{ marginTop: "24px", fontSize: "16px" }}><div className="rowStick"></div>Your Benefits:</div>
                {job.benefits.map((benefit, i) => <li className="ctJDJTTitle" key={i}>{benefit}</li>)}
            </ul>
            <div className="ctJDContent forApply">
                <div className="small">Before you submit, please include links to your recent projects in your CV. We will contact you soon.</div>
                
                <Switch>
                    <Route exact path="/job"><div className="apply" style={{ opacity: "0.7", cursor: "default" }}>Submit your CV</div></Route>
                    <Route path="/job/:somestring"><div className="apply" style={{ textDecoration: "none" }} onClick={function () { window.open('mailto:career@tapuniverse.com'); }}>Submit your CV</div></Route>
                </Switch>
                
                <div className="small small1">If you can't click the button above, please send your CV to career@tapuniverse.com.</div>
            </div>
        </div>
    )
}
function RequireHastag(props) {
    return (
        <div className="requireCarry">
            {props.hashtag}
        </div>
    )
}

function JobCard(props) {
    // console.log(document.body.clientWidth)
    function JobChoose() {
        var jobList = document.querySelectorAll(".ctJobCard")
        jobList.forEach((job, i) => {
            if (job !== null)
                job.classList.remove("ctJobCardFocus")
        })
        if (document.body.clientWidth < 900) {
            if (document.querySelector(".backTab") !== null)
                document.querySelector(".backTab").style.display = "none"
            if (document.querySelector(".backJob") !== null)
                document.querySelector(".backJob").style.display = "flex"
            if (document.querySelector(".ctJobsTab") !== null)
                document.querySelector(".ctJobsTab").style.display = "none"
            if (document.querySelector(".ctJobDetail_highRe") !== null)
                document.querySelector(".ctJobDetail_highRe").style.display = "block"
            if (document.querySelector(".careersTabBody") !== null)
                document.querySelector(".careersTabBody").style.width = "100%"
        }
        if (document.getElementById(props.id) !== null)
            document.getElementById(props.id).classList.add("ctJobCardFocus")
    }

    var path = window.location.pathname.split("/"); 
    var selected = path[path.length-1];

    return (
        <>
            <Link
                to={"/job/" + props.id}
                className={props.id == selected ? "ctJobCard ctJobCardFocus" : "ctJobCard"}
                id={props.id}
                style={{ textDecoration: "none" }}
                onClick={JobChoose}
            >
                <div className="ctJCTitle">
                    <div className="ctJCName">{props.experience} {props.name} <span className="openpos">{props.openPosition}</span></div>
                    <div className="ctJCSalary">{props.salary}</div>
                </div>
                <div className="requires">
                    {props.requires.map((require, i) => <RequireHastag hashtag={require} key={i} />)}
                </div>
            </Link>

        </>
    )
}

export default function Careers(props) {
    if (props.data !== null) {
        jobList = props.data.jobList
        contactInfo = props.data.contactInfo
    }
    const [state, setState] = useState(0)
    function update() {
        setState(state + 1)
    }

    React.useEffect(function () {
        if (document.body.clientWidth < 900) {
            var path = window.location.pathname.split("/"); 
            var selected = path[path.length-1];
            var inside = parseInt(selected) > 0
            if (document.querySelector(".backTab") !== null)
                document.querySelector(".backTab").style.display = inside ? "none" : "flex"
            if (document.querySelector(".backJob") !== null)
                document.querySelector(".backJob").style.display = inside ? "flex" : "none"
            if (document.querySelector(".ctJobsTab") !== null)
                document.querySelector(".ctJobsTab").style.display = inside ? "none" : "block"
            if (document.querySelector(".ctJobDetail_highRe") !== null)
                document.querySelector(".ctJobDetail_highRe").style.display = inside ? "block" : "none"
            if (document.querySelector(".careersTabBody") !== null)
                document.querySelector(".careersTabBody").style.width = "calc(100vw - 12px)"
        }
        window.scrollTo(0, 0);  
    }, []);

    return (
        <div className="careersTab">
            <div className="careersTabTop">
                <div className="careersTabTopCarry">
                    <Link to="/" className="backTab" onClick={function () {
                        setTimeout(() => {
                            window.scrollTo(0, document.querySelector(".Allin").clientHeight)
                        }, 50)
                    }}>
                        <div style={{ marginRight: "10px" }} > <img src={Vector} alt="<" style={{ marginTop: "5px" }} />
                        </div>
                        Home
                    </Link>
                    <Link to="/job" className="backJob" style={{ zIndex: "1", position: "absolute", display: "none" }} onClick={function () {
                        if (document.querySelector(".backTab") !== null)
                            document.querySelector(".backTab").style.display = "flex"
                        if (document.querySelector(".backJob") !== null)
                            document.querySelector(".backJob").style.display = "none"
                        if (document.querySelector(".ctJobsTab") !== null)
                            document.querySelector(".ctJobsTab").style.display = "block"
                        if (document.querySelector(".ctJobDetail_highRe") !== null)
                            document.querySelector(".ctJobDetail_highRe").style.display = "none"
                        if (document.querySelector(".careersTabBody") !== null)
                            document.querySelector(".careersTabBody").style.width = "100%"
                        if (document.querySelectorAll(".ctJobCard") !== null)
                            document.querySelectorAll(".ctJobCard").forEach((job, i) => { job.classList.remove("ctJobCardFocus") })
                    }}>
                        <div style={{ marginRight: "10px" }} > <img src={Vector} alt="<" style={{ marginTop: "5px" }} />
                        </div>
                        All Jobs
                    </Link>
                    <div className="careersTabText">Careers</div>
                    <div className="careersTabCenter">
                        <div className="logo" style={{ backgroundImage: "url(/https://storage.tapuniverse.com/tapuniverse-website/TapUniverseLogo.png)" }}></div>
                    </div>
                    
                </div>
            </div>
            <div className="careersTabBody">
                <div className="careersTabBodyCarry">
                    <div className="ctJobsTab">
                        {jobList.map((job, i) =>
                            <JobCard
                                id={job.id}
                                key={i}
                                index={i}
                                update={update}
                                name={job.jobName}
                                experience={job.experience}
                                salary={job.salary[1]}
                                requires={job.requires}
                                benefits={job.benefits}
                                workingOn={job.workingOn}
                                talentRequires={job.talentRequires}
                                openPosition={job.openPosition}
                            />)}
                    </div>
                    <div className="ctJobDetail_highRe">
                        <Switch>
                            {jobList.map((job, i) => <Route path={"/job/" + job.id} key={i} ><JobDetail job={job} /></Route>)}
                        </Switch>
                        <div className="ctContactInfo">
                            <div className="ctContactTittle">Contact Information</div>
                            <ul className="ctContactContent">
                                {contactInfo.map((info, i) => <li key={i}>{info} <br /><br /></li>)}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="careerFooter">
                <div className="careerFooterContent">
                    Copyright ©2021 TapUniverse LLC. All rights reserverd.
                </div>
            </div>
        </div >
    )
}
import './Admin.css'
import React, { useState } from 'react';
import DownloadLink from "react-download-link";
import axios from 'axios';




export default function Admin(props) {
    var Data = props.data
    const [state, setState] = useState({ selectedFile: null })
    function onUploading(e) {
        setState({ selectedFile: e.target.files[0] });
    }
    async function Uploaded() {
        console.log("0")
        if (state.selectedFile !== null) {
            console.log("1")
            var reader = new FileReader()
            reader.readAsText(state.selectedFile, "utf8")
            reader.onerror = function(e) {
                console.log(e)
            }
            reader.onload = async function (e) {
                document.querySelector(".inputFile").value = []
                console.log("2")
                await axios.post('./post/data_1', [JSON.parse(e.target.result), document.querySelector(".password").value])
                document.querySelector(".logError").textContent = "File sent"
                console.log("3")
                setTimeout(() => {
                    document.querySelector(".logError").textContent = ""
                }, 3000);
                await axios.get('/api/data_1').then(res => res.data).then(data => Data = data)
            }
        }
    }
    return (
        <div className="admin">
            <div className="adminCarry">
                <div className="div" style={{ fontSize: "24px" }}>Password: <input type="password" className="password" onKeyDown={props.enter} /></div>
                <div className="logError"></div>
                <input type="file" onChange={onUploading} className="inputFile" />
                <div className="downUp">
                    <button className="download" style={{ textDecoration: "none" }}><DownloadLink
                        label="Download"
                        filename="Data.json"
                        exportFile={() => JSON.stringify(Data)
                        }
                    /></button>
                    <button className="upload" onClick={Uploaded}>Upload</button>
                </div>
            </div>
        </div>
    )
}
import './App.css';
import MainScene from './Components/MainScene/MainScene';
import Careers from './Components/Careers/Careers';
import Admin from './Components/Admin/Admin';
import { Switch, Route } from 'react-router-dom'
import React from 'react';
import axios from 'axios'
import initReactFastclick from 'react-fastclick';

initReactFastclick();


const Data = () => {
  return axios.get('/api/data_1')
    .then((res) => {
      return res.data
    })
}

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null
    }
  }

  componentWillMount() {
    if (this.state.data === null) {
      Data().then((res) => {
        this.setState({
          data: res
        })
      })
    }
  }
  
  render() {
    return (<div className="Allin">
        <Switch>
          <Route exact path="/"><MainScene data={this.state.data} /></Route>
          <Route path="/job"><Careers data={this.state.data} /></Route>
          <Route exact path="/admin"><Admin data={this.state.data} /></Route>
        </Switch>
    </div>
    )
  }

}

export default App;
